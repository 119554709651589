<template>
  <div class="detail">
    <div v-loading="loading.detail" class="detail-inner">
      <div v-if="needBuy" class="charge-tips">
        <div class="content">
          <span class="tip-text"
            >你所查看的族谱是收费项目，需要购买才能查看全部</span
          >
          <div class="buy-btn" @click="showPayDialog">
            <span class="text">购买</span>
          </div>
        </div>
        <!-- <img
          class="close-icon"
          src="@/assets/images/detail/img_64679_0_2.png"
        /> -->
      </div>
      <div class="detail-main" :class="{ 'hide-menu': hideMenu }">
        <div class="left-catalogue">
          <div class="scale-title">
            <div class="title-text">
              <span class="shipu-text"
                >{{ detail.title }}（{{ detail.juanming }}）</span
              >
              <span class="fangpu-text">{{ detail.timing || "xx房" }}</span>
            </div>
            <img
              v-if="!hideMenu"
              class="scale-icon"
              src="@/assets/images/detail/expand.png"
              @click="toggleHideMenu"
            />
            <img
              v-else
              class="scale-icon"
              src="@/assets/images/detail/scale.png"
              @click="toggleHideMenu"
            />
          </div>
          <div
            v-if="!hideMenu"
            v-loading="loading.cata"
            class="catalogue-content"
          >
            <span class="catalogue-title">目录</span>
            <div class="split-line" />
            <div v-for="item in cata" class="sub-juan-title" :key="item.id">
              <span class="sub-juan-text" @click="navigateToDetail(item.id)"
                >{{ item.title }}（{{ item.juanming }}）</span
              >
              <!-- <img
                class="down-arrow-icon"
                src="@/assets/images/detail/img_64679_0_4.png"
              /> -->
            </div>
            <!-- <span class="shixi-title">世系/世表</span>
            <span class="fang-title">xxxx房</span>
            <span class="fang-title-no-active">xxxx房</span> -->
          </div>
        </div>
        <div v-loading="loading.browser" class="detail-browser">
          <template v-if="pdfImages.length > 0">
            <div class="browser-right" @click="showPreview = true">
              <img v-if="leftImg" class="pdf-image" :src="leftImg" alt="" />
            </div>
            <img
              class="icon-left"
              src="@/assets/images/detail/img_64679_0_5.png"
              @click="handleTurnPage('prev')"
            />
            <img
              class="icon-right"
              src="@/assets/images/detail/img_64679_0_6.png"
              @click="handleTurnPage('next')"
            />
            <div class="pager">第{{ currPage + 1 }} / {{ pdfTotalPage }}页</div>
          </template>

          <!-- <flip
            ref="flip"
            v-if="pdfImages.length > 0"
            :images="pdfImages"
            @change-page="handleTurnPage"
          >
          </flip> -->
          <div v-else class="empty">{{ needBuy ? "需要付费" : "加载中" }}</div>
        </div>
      </div>
    </div>
    <pay-dialog
      :bookId="detail.id"
      :bookPrice="detail.price"
      ref="pay-dialog"
      @success="fetchDetail"
    />
    <tips-dialog ref="tips" title="提示">
      <img class="icon" src="@/assets/warn.png" alt="" />
      <div class="tips-title">试用页已看完，是否继续观看？</div>
      <div class="tips-desc">
        如继续观看，则将消耗收费谱书名额
        <span style="font-size: 12px"
          >(<span class="remain">{{ userInfo.last_count }}</span
          >/{{ userInfo.last_count + userInfo.view_count }})</span
        >
      </div>
      <button class="confirm-next-btn" @click="confirmNext">继续</button>
    </tips-dialog>
    <van-image-preview
      v-model="showPreview"
      ref="image-preview"
      :images="pdfImages"
      :start-position="currPage"
      :loop="false"
      @change="handlePreviewChange"
    >
      <template v-slot:index
        >第{{ currPage + 1 }} / {{ pdfTotalPage }}页</template
      >
      <template v-slot:cover>
        <!-- <div class="preview-wrapper"> -->
        <img
          class="icon-left"
          src="@/assets/images/detail/img_64679_0_5.png"
          @click="handleTurnPage('prev')"
        />
        <img
          class="icon-right"
          src="@/assets/images/detail/img_64679_0_6.png"
          @click="handleTurnPage('next')"
        />
        <!-- </div> -->
      </template>
    </van-image-preview>
  </div>
</template>

<script>
import { mapState } from "vuex";
import materialApis from "@/apis/material";
import PayDialog from "@/components/web/PayDialog.vue";
import TipsDialog from "@/components/web/Tips";
import { ImagePreview } from "vant";
import "@vant/touch-emulator";

export default {
  name: "Detail",
  components: {
    PayDialog,
    TipsDialog,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  props: {
    propDetail: null
  },
  data() {
    return {
      loading: {
        detail: false,
        cata: false,
        browser: false
      },
      hideMenu: false,
      detail: {},
      cata: [],
      pdfPage: 1,
      currPage: 0,
      pdfNomore: false,
      pdfTotalPage: 10000,
      pdfImages: [],
      needBuy: false,
      showPreview: false
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    leftImg() {
      return this.pdfImages[this.currPage];
    }
  },
  async mounted() {
    if (this.propDetail) {
      this.detail = this.propDetail;
    } else {
      this.fetchDetail();
    }
    // this.fetchPdfs(4);
    this.unlisten = this.initEvents();
  },
  beforeDestroy() {
    this.unlisten && this.unlisten();
  },
  methods: {
    initEvents() {
      const loginListener = () => {};

      this.bus.on("login-success", loginListener);

      return () => {
        this.bus.off("login-success", loginListener);
      };
    },

    showPayDialog() {
      this.showPreview = false;
      if (this.userInfo.last_count > 0) {
        // 是vip 使用vip次数
        this.$refs["tips"].show();
      } else {
        // 购买
        this.$refs["pay-dialog"].show();
      }
    },

    async fetchDetail() {
      this.loading.detail = true;
      try {
        const id = this.$route.query.id || this.$route.params.id;
        const {
          data: { material_info, isbuy, isduihuan }
        } = await materialApis.materialDetail({ id });
        this.detail = { ...material_info, isbuy, isduihuan };
        // if (parseFloat(material_info.price) > 0 && !this.userInfo.isLogin) {
        //   this.$router.replace("/h5/login");
        // }
      } catch (error) {
        console.error(error);
      }
      this.loading.detail = false;
    },

    async fetchCata({ sn }) {
      this.loading.cata = true;
      const {
        data: { material_list }
      } = await materialApis.menuList({ sn });
      this.cata = material_list;
      this.loading.cata = false;
    },

    handleTurnPage(type) {
      if (!this.pdfImages[this.currPage]) return;
      if (type === "next") {
        if (!this.pdfImages[this.currPage + 1]) return;
        this.currPage += 1;
      } else {
        if (!this.pdfImages[this.currPage - 1]) return;
        this.currPage -= 1;
      }

      if (this.pdfImages.length - this.currPage <= 2) {
        if (
          this.detail.isbuy ||
          this.detail.isduihuan ||
          parseFloat(this.detail.price) <= 0
        ) {
          this.fetchPdfs();
        } else {
          if (this.currPage === this.pdfImages.length - 1) {
            this.showPayDialog();
            this.currPage -= 1;
          }
        }
      }
    },

    handlePreviewChange(page) {
      console.log("onchange", page, this.currPage);
      if (page === this.currPage) return;
      if (page > this.currPage) {
        this.handleTurnPage("next");
      } else {
        this.handleTurnPage("prev");
      }
    },

    async fetchPdfs(page = 1) {
      // if (!this.userInfo.id && this.detail.price > 0) return; // 价格大于0 且未登陆 不请求pdf
      if (this.pdfPage > 1 && this.pdfPage >= this.pdfTotalPage) return; // 超过最大数量
      // if (this.detail.price > 0) {
      //   // 超过最大可读数量
      //   this.showPayDialog();
      //   return;
      // }

      this.loading.browser = true;
      while (page > 0) {
        await materialApis
          .materialDetailPdf({
            id: this.$route.query.id || this.$route.params.id,
            page: this.pdfPage
          })
          .then(({ data: { pdf_path: pdfImage, totalpage } }) => {
            this.pdfImages.push(pdfImage);
            this.pdfTotalPage = totalpage;
            this.pdfPage += 1;
            // this.$refs["flip"]?.updatePage();
          })
          .catch(() => {
            page = 0;
          });
        page -= 1;
      }
      this.loading.browser = false;
    },

    navigateToDetail(id) {
      if (
        id == (this.$route.query.id || this.$route.params.id) ||
        this.loading.detail
      )
        return;
      this.refreshPdf();
      this.$router.replace({
        path: "/web/search/detail",
        query: { id }
      });
      // this.fetchDetail();
    },

    toggleHideMenu() {
      this.hideMenu = !this.hideMenu;
    },

    async confirmNext() {
      this.$refs["tips"].hide();
      await this.fetchPdfs();
      this.fetchDetail();
    },

    refreshPdf() {
      this.pdfPage = 1;
      this.currPage = 0;
      this.pdfImages = [];
    },

    handleDetailChange(detail) {
      // 获取完谱书详情，根据谱书是否收费，判断是否需要弹出登陆窗
      // console.log(
      //   detail.id,
      //   parseFloat(detail.price),
      //   detail.isbuy,
      //   this.userInfo.isLogin
      // );
      if (!detail.id) return;
      // this.refreshPdf();
      if (parseFloat(detail.price) === 0 || detail.isbuy || detail.isduihuan) {
        // 免费 或 已购买 拉取pdf
        this.needBuy = false;
        this.fetchPdfs(4);
      } else {
        this.needBuy = true;
        if (!this.userInfo.isLogin) {
          // 未登陆
          console.log("为登陆");
          this.bus.emit("login");
        } else {
          // 已登陆 显示购买或者消耗次数弹窗
          console.log("fetch");
          this.fetchPdfs(detail.view_page);
          // this.showPayDialog();
        }
      }
    }
  },

  watch: {
    detail: {
      immediate: true,
      handler(val, oldVal) {
        if (val?.sn && val?.sn !== oldVal?.sn) {
          this.fetchCata({ sn: val.sn });
        }
        this.handleDetailChange(val);
      }
    },
    $route(val, oldVal) {
      if (val.query.id !== oldVal.query.id) {
        this.detail = {};
        this.fetchDetail();
      }
    },
    "userInfo.isLogin": {
      handler(val) {
        if (val) {
          console.log(val);
          this.fetchDetail();
        }
      }
    }
  }
};
</script>
<style>
.van-image__img {
  width: auto;
}
</style>
<style lang="scss" scoped>
.detail {
  display: flex;
  justify-content: center;
  // background: linear-gradient(0deg, #fbe2c4 0%, #faf3ea 100%);
  height: calc(100vh - 70px);

  .detail-inner {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
  }

  .charge-tips {
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #f7d1a3;
    padding-right: 20px;
    padding-left: 21px;
    width: 1200px;
    height: 50px;
    overflow: hidden;
    flex: none;

    .content {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 30px;

      .tip-text {
        margin-right: 14px;
        height: 15px;
        font-size: 14px;
        color: #CA0A15;
        line-height: 15px;
        webkit-line-clamp: 2px;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        letter-spacing: 0px;
      }

      .buy-btn {
        cursor: pointer;
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        width: 80px;
        height: 30px;
        background: linear-gradient(-90deg, #ee6a0c 0%, #fd9e3b 100%);
        border-radius: 15px;

        .text {
          position: relative;
          max-width: 39px;
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          letter-spacing: 0px;
          white-space: nowrap;
          color: #ffffff;
          font-size: 12px;
        }
      }
    }

    .close-icon {
      width: 10px;
      height: 10px;
    }
  }

  .detail-main {
    display: flex;
    position: relative;
    align-items: center;
    align-self: center;
    flex-direction: row;
    // margin-top: 10px;
    // margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    height: calc(100% - 60px);
    flex: 1;
    box-sizing: border-box;

    &.hide-menu {
      flex-flow: column nowrap;
      height: 100%;

      .left-catalogue {
        width: 100%;
        height: auto;
        margin-right: 0;

        .scale-title {
          width: 100%;
        }
      }

      .detail-browser {
        width: 100%;
      }
    }

    .left-catalogue {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-right: 20px;
      height: 100%;
      transition: width 0.3s ease;
      // height: 920px;

      .scale-title {
        box-sizing: border-box;
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.2);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-color: #CA0A15;
        padding: 20px;
        width: 200px;
        // height: 50px;
        min-height: 50px;
        overflow: hidden;
        transition: width 0.3s ease;

        .title-text {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding-right: 5px;
          // height: 32px;

          .shipu-text {
            position: relative;
            margin-left: 1px;
            text-decoration: none;
            line-height: 1.5;
            letter-spacing: 0px;
            // white-space: nowrap;
            color: #ffffff;
            font-size: 14px;
          }

          .fangpu-text {
            position: relative;
            margin-top: 6px;
            max-width: 52px;
            height: 12px;
            overflow: hidden;
            text-decoration: none;
            text-overflow: ellipsis;
            line-height: 12px;
            letter-spacing: 0px;
            white-space: nowrap;
            color: #ffffff;
            font-size: 12px;
          }
        }

        .scale-icon {
          width: 20px;
          height: 17px;
          cursor: pointer;
        }
      }

      .catalogue-content {
        display: flex;
        position: relative;
        align-items: flex-start;
        flex-direction: column;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: #ffffff;
        width: 200px;
        overflow: auto;
        padding: 10px 0;

        // height: 870px;
        // overflow: hidden;
        flex: 1;

        .catalogue-title {
          position: relative;
          margin-top: 28px;
          margin-left: 23px;
          max-width: 177px;
          height: 13px;
          // overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          line-height: 14px;
          letter-spacing: 0px;
          white-space: nowrap;
          color: #CA0A15;
          font-size: 14px;
        }

        .split-line {
          position: relative;
          align-self: center;
          margin-top: 15px;
          box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.2);
          background-color: #CA0A15;
          width: 160px;
          height: 1px;
          flex: none;
          // overflow: hidden;
        }

        .sub-juan-title {
          display: flex;
          position: relative;
          align-items: center;
          align-self: center;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 10px;
          width: 159px;
          // height: 12px;

          .sub-juan-text {
            // max-width: 140px;
            // height: 12px;
            overflow: hidden;
            text-decoration: none;
            line-height: 1.5;
            letter-spacing: 0px;
            color: #CA0A15;
            font-size: 12px;
            cursor: pointer;
            // word-break: break-all;
          }

          .down-arrow-icon {
            width: 7px;
            height: 4px;
          }
        }

        .shixi-title {
          position: relative;
          margin-top: 14px;
          margin-left: 21px;
          max-width: 179px;
          height: 12px;
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          line-height: 12px;
          letter-spacing: 0px;
          white-space: nowrap;
          color: #CA0A15;
          font-size: 12px;
        }

        .fang-title {
          position: relative;
          margin-top: 13px;
          margin-left: 40px;
          max-width: 160px;
          height: 12px;
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          line-height: 12px;
          letter-spacing: 0px;
          white-space: nowrap;
          color: #CA0A15;
          font-size: 12px;
        }

        .fang-title-no-active {
          position: relative;
          margin-top: 9px;
          margin-left: 40px;
          max-width: 160px;
          height: 12px;
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          line-height: 12px;
          letter-spacing: 0px;
          white-space: nowrap;
          color: #666666;
          font-size: 12px;
        }
      }
    }
  }

  .detail-browser {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border-radius: 4px;
    background-color: #ffffff;
    // width: 980px;
    height: 100%;
    overflow: hidden;
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    transition: width 0.3s ease;

    .pdf-image {
      height: calc(100% - 10px);
      margin-top: -10px;
      width: 100%;
      object-fit: contain;
    }

    .browser-left {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-right: 5px;
      border-radius: 4px;
      // background-color: #CA0A15;
      width: 50%;
      height: 100%;
      overflow: hidden;

      .icon-left {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        overflow: hidden;
        cursor: pointer;
        background-color: #fff;
      }
    }

    .browser-right {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-radius: 4px;
      // background-color: #CA0A15;
      // width: 50%;
      height: 100%;
      overflow: hidden;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.tips-title {
  margin-top: 19px;
  font-size: 16px;
  color: #CA0A15;
}

.tips-desc {
  margin-top: 14px;
  font-size: 14px;
  color: #666666;

  .remain {
    color: #ff5500;
  }
}

.confirm-next-btn {
  margin-top: 30px;
  height: 40px;
  width: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #CA0A15;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.empty {
  height: 100%;
  widows: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;
  pointer-events: none;
}

.icon-left {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
}

.icon-right {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
}

.van-image-preview__cover {
  .icon-left {
    position: fixed;
    left: 20px;
    top: 50vh;
    // transform: translateY(-50%);
    width: 40px;
    height: 40px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
  }

  .icon-right {
    position: fixed;
    right: 20px;
    top: 50vh;
    // transform: translateY(-50%);
    width: 40px;
    height: 40px;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
  }
}

.pager {
  position: absolute;
  bottom: 10px;
  font-size: 12px;
}
</style>
